import React, { useEffect, useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SoundCloud from '../components/soundcloud'
import Mixcloud from '../components/mixcloud'

const MixesPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    (async() => {

      let { mixes } = await import('../data/mixes');

      setData(mixes.slice().sort().reverse());
    })();
  }, []);

  return (
    <Layout>
      <SEO title="Mixes" description="A series of mixes from artists & friends of Nutriot Recordings" />
      {
        data.length && data.map(item => {
          return (
            <section
              key={item.id}
              className="item"
            >
              {
                item.source === 'soundcloud'
                  ? (<SoundCloud {...item} />)
                  : (<Mixcloud {...item} />)
              }
            </section>

          )
        })
      }
    </Layout>
  );
};

export default MixesPage

import React from 'react';

import Embed from './embed';

export default props => {
    const feed = encodeURIComponent(`/${props.id}/`)
    const src = `https://www.mixcloud.com/widget/iframe/?feed=${feed}`;
    
    return (
        <Embed
            frameBorder="0"
            loading="lazy"
            src={src}
            style={props.style}
            title={props.title}
        />
    );
};
